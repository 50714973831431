export function getUserAgent() {
    return window.navigator.userAgent.toLowerCase();
}

export function isMobile() {
    // const userAgent = getUserAgent();
    const width = window.innerWidth;

    // if (userAgent.includes('iphone') || userAgent.includes('android')) {
    //     // return 1 for phones, 2 for tablets

    // }

    const m = width > 677 ? width > 920 ? 0 : 2 : 1;


    return m

    // return 0; // return 0 for none (desktop or unsupported devices)
}
