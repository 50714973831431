import React, { useEffect, useState } from 'react';
import { getCaptionUrl } from "../firebaseConfig";

function timeToSeconds(timestamp) {
    const [time, ms] = timestamp.split(','); // Separate milliseconds
    const [hours, minutes, seconds] = time.split(':').map(Number); // Split time and convert to numbers

    return (hours * 3600) + (minutes * 60) + seconds + (ms / 1000); // Convert to total seconds
}

function Subtitles({ lang = "en", id, videoRef, backgroundColor = "#0005", color = "#fff", size = "medium", bottomPosition = "10px" }) {
    const [currentText, setCurrentText] = useState("");
    const [captions, setCaptions] = useState([]);
    const [positionStyle, setPositionStyle] = useState({ bottom: bottomPosition, top: 'unset' });

    const getSize = (size) => {
        switch (size) {
            case 'extra small': return '12px';
            case 'small': return '16px';
            case 'medium': return '20px';
            case 'large': return '24px';
            case 'extra large': return '28px';
            default: return '20px'; // Default to medium if size is not specified
        }
    };

    useEffect(() => {
        const regex = /\d+\s+(\d{2}:\d{2}:\d{2},\d{3})\s-->\s(\d{2}:\d{2}:\d{2},\d{3})\s*([\s\S]*?)(?=\n\d|\n$)/g;

        const fetchCaptions = async () => {
            const url = await getCaptionUrl(id, lang);

            const response = await fetch(url);
            if (!response.ok) return;

            const text = await response.text();

            // console.log(text);
            const matches = Array.from(text.matchAll(regex)).map(match => ({
                start: timeToSeconds(match[1]),
                end: timeToSeconds(match[2]),
                text: match[3].trim()
            }));

            setCaptions(matches);
        };

        fetchCaptions();
    }, [id, lang]);

    useEffect(() => {
        const handleTimeUpdate = () => {
            const currentTime = videoRef.current.currentTime;

            const currentCaption = captions.find(
                caption => currentTime >= caption.start && currentTime <= caption.end && !caption.text.includes("YTS.MX")
            );

            if (currentCaption) {
                let captionText = currentCaption.text;

                // Check for {\an8} and adjust positioning
                if (captionText.includes('{\\an8}')) {
                    setPositionStyle({ bottom: 'unset', top: '75px' }); // Position at top
                    captionText = captionText.replace('{\\an8}', '').trim(); // Remove {\an8} tag
                } else {
                    setPositionStyle({ bottom: bottomPosition, top: 'unset' }); // Reset to bottom
                }

                setCurrentText(captionText);
            } else {
                setCurrentText("");
            }
        };

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [captions, videoRef, bottomPosition]);

    return (
        <>
            {currentText && (
                <div
                    style={{
                        pointerEvents: 'none',
                        padding: '5px 10px',
                        borderRadius: '10px',
                        backgroundColor: backgroundColor,
                        color: color,
                        fontSize: getSize(size),
                        position: "absolute",
                        left: "50vw",
                        transform: "translateX(-50%)",
                        transition: ".1s",
                        zIndex: 12,
                        ...positionStyle
                    }}
                >
                    {currentText.includes('<i>') ? (
                        <i>
                            {currentText
                                .replace('<i>', '')
                                .replace('</i>', '')
                                .split('\n')
                                .map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                        </i>
                    ) : (
                        currentText
                            .split('\n')
                            .map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))
                    )}
                </div>
            )}

        </>

    );
}

export default Subtitles;
