import { useNavigate, useLocation } from "react-router-dom"

function BottomNavbar() {
    const navigate = useNavigate()
    const location = useLocation()

    const isActive = (path) => location.pathname === path

    return (
        <div className="bottomNavbar">
            <div style={{ display: 'flex', flex: 1, maxWidth: '678px', margin: 'auto' }}>
                <button onClick={() => navigate("/")}>
                    <ion-icon name={`${isActive("/") ? 'planet' : 'planet-outline'}`}></ion-icon>
                </button>

                <button onClick={() => navigate("/movies")}>
                    <ion-icon name={`${isActive("/movies") ? 'videocam' : 'videocam-outline'}`}></ion-icon>
                </button>

                <button onClick={() => navigate("/tvs")}>
                    <ion-icon name={`${isActive("/tvs") ? 'tv' : 'tv-outline'}`}></ion-icon>
                </button>

                <button onClick={() => navigate("/search")}>
                    <ion-icon name={`${isActive("/search") ? 'search' : 'search-outline'}`}></ion-icon>
                </button>
            </div>


        </div>
    )
}

export default BottomNavbar
