import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { fetchSharedToken } from "./firebaseConfig";
import { findById } from "./Themoviedb";

import Skeleton from "./components/Skeleton";

const SharedContent = () => {
    const { token } = useParams();
    const [isValid, setIsValid] = useState(null);
    const [isActive, setIsActive] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
            try {
                const result = await fetchSharedToken(token);

                if (!result.valid) {
                    setIsValid(false);
                    return;
                }

                setIsValid(true);
                const data = result.data;

                if (data.isActive) {
                    setIsActive(true);
                    const contentData = await findById(data.imdb_id);
                    const content = Object.values(contentData).flat(1)[0];

                    navigate(`/${content.media_type}/${content.id}`, {
                        state: {
                            backgroundLocation: { pathname: '/' }
                        }
                    });
                } else {
                    setIsActive(false);
                }
            } catch (error) {
                console.error("Error validating token:", error);
                setIsValid(false); // You might want to set this to false if there's an error
            }
        };

        validateToken();
    }, [token, navigate]);

    // Loading state
    if (isValid === null) {
        return <Skeleton />;
    }

    // Invalid token
    if (!isValid) {
        return <Message text="Token is not valid. This token is either expired or was never existed at all." />
    }

    // Token is not active
    if (isActive === false) {
        return <Message text="Token is not active. This token may have been banned by the user who sent it." />;
    }

    return null;
};

function Message({ text, redirect = "/" }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px", fontSize: "11px", alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <p>{text}</p>
            <Link to={redirect} className="button" style={{ textDecoration: 'none' }}>Okay :\</Link>
        </div>
    );
}

export default SharedContent;
