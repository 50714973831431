import { useEffect, useState } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import PlinxLogo from './image/logo.png'; // Assuming the logo is located in the image folder
import { auth } from './firebaseConfig'; // Import Firebase Auth
import { createUserWithEmailAndPassword } from 'firebase/auth'; // Firebase sign-in function
import { getDatabase } from 'firebase/database';
import { ref, set } from "firebase/database"; // Firebase real-time database functions
import { generateToken } from './Token';

import { useAuth } from './AuthContext';

const fetchMovies = async (page) => {
    const url = `https://api.themoviedb.org/3/discover/movie?include_adult=false&include_video=false&language=en-US&page=${page}&sort_by=popularity.desc`;
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: 'Bearer YOUR_API_KEY' // Replace with your actual API key
        }
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

function SignUp() {
    const db = getDatabase()
    const [showPassword, setShowPassword] = useState(false);
    const [backdrops, setBackdrops] = useState([]);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate(); // useNavigate for redirection

    const { account } = useAuth()

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleCreate = async (account) => {
        setLoading(true);
        try {
            if (!username) {
                throw new Error("Username is required");
            }

            // Reference to the user's profiles in the real-time database
            const userRef = ref(db, `users/${account.user.uid}`);
            console.log(account)
            const token = generateToken()

            const newProfile = {
                username,
                token,
                profileUrl: "https://www.kaericature.com/wp-content/uploads/2023/05/custom_avatar3_3d-300x300.jpg"
            }
            // Create a new profile with a unique key under the current user
            set(userRef, {
                email: email,
                uid: account.user.uid,
                profiles: [newProfile]
            }).then(() => {
                // console.log("hi")
                localStorage.setItem('currentUser', token)
                window.location.pathname = "/"
            })

            // On success, navigate to home


        } catch (err) {
            setError(err.message); // Display error message
        } finally {
            setLoading(false);
        }
    };


    const handleSignUp = async () => {
        if (password !== verifyPassword) {
            setError("Passwords do not match.");
            return;
        }
        setLoading(true); // Set loading to true
        try {
            const account = await createUserWithEmailAndPassword(auth, email, password);
            await handleCreate(account)
        } catch (err) {
            switch (err.code) {
                case 'auth/email-already-in-use':
                    setError("Email already in use.");
                    break;
                case 'auth/invalid-email':
                    setError("Invalid email format, plinx@example.com.");
                    break;
                case 'auth/weak-password':
                    setError("Password should be at least 6 characters.");
                    break;
                default:
                    setError("An error occurred. Please try again.");
            }
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    useEffect(() => {
        const fetchMultiplePages = async () => {
            let allMovies = [];
            const totalPagesToFetch = 3; // Number of pages to fetch (adjust as needed)

            for (let page = 1; page <= totalPagesToFetch; page++) {
                try {
                    const data = await fetchMovies(page);
                    allMovies = [...allMovies, ...data.results];
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

            setBackdrops(allMovies.map(i => i.backdrop_path));
        };

        // fetchMultiplePages();
    }, []);

    return (
        <div className="loginContainer">
            {/* <div className='movieGrid'>
                {Array.from({ length: 6 }).map((_, i) => (
                    <>
                        <div className='movieRow' style={{ animation: `goleft ${100 * i}s infinite linear` }} key={i}>
                            {[...backdrops.slice(0, 13), ...backdrops.slice(0, 13)].map((url, j) => (
                                <div className='movieGridItem' key={j}>
                                    <div style={{
                                        backgroundImage: `url(https://image.tmdb.org/t/p/original/${url})`,
                                        height: "100%",
                                        width: "100%",
                                        backgroundSize: "cover"
                                    }}></div>
                                </div>
                            ))}
                        </div>
                        <div className='movieRow' style={{ animation: `goright ${100 * i}s infinite linear` }} key={i}>
                            {[...backdrops.slice(13, 26), ...backdrops.slice(13, 26)].map((url, j) => (
                                <div className='movieGridItem' key={j}>
                                    <div style={{
                                        backgroundImage: `url(https://image.tmdb.org/t/p/original/${url})`,
                                        height: "100%",
                                        width: "100%",
                                        backgroundSize: "cover"
                                    }}></div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div> */}

            <div className="box">
                <div className="logoDiv">
                    <img src={PlinxLogo} alt="Plinx Logo" className="plinxLogo" />
                </div>

                <div className="inpuDiv">
                    <input
                        type="text"
                        placeholder="Username"
                        className="loginInput"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className="inpuDiv">
                    <input
                        type="email"
                        placeholder="E-mail"
                        className="loginInput"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="inpuDiv">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className="loginInput"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="checkbox"
                        id="seePass"
                        style={{ display: 'none' }}
                        onChange={togglePasswordVisibility}
                    />
                    <label htmlFor="seePass">
                        <ion-icon
                            name={showPassword ? "eye-off" : "eye"}
                            style={{ color: "#fff", cursor: "pointer" }}
                        />
                    </label>
                </div>

                <div className="inpuDiv">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Verify Password"
                        className="loginInput"
                        value={verifyPassword}
                        onChange={(e) => setVerifyPassword(e.target.value)}
                        style={{
                            outline: verifyPassword === password ? "none" : "1px solid #500"
                        }}
                    />
                    <input
                        type="checkbox"
                        id="seePass"
                        style={{ display: 'none' }}
                        onChange={togglePasswordVisibility}
                    />
                    <label htmlFor="seePass">
                        <ion-icon
                            name={showPassword ? "eye-off" : "eye"}
                            style={{ color: "#fff", cursor: "pointer", opacity: 0 }}
                        />
                    </label>
                </div>

                {error && <div style={{ color: 'red', fontSize: "11px" }}>{error}</div>}

                <div className="buttonDiv">
                    <button className="loginButton" onClick={handleSignUp} disabled={loading}>
                        {loading ? 'Loading...' : 'SignUp'}
                    </button>
                    <Link to='/login' className="signupButton">Login</Link>
                </div>
            </div>
            {account && (<Navigate to="/" replace />)}
        </div>
    );
}

export default SignUp;
