import { useEffect, useRef, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
// import { getDatabase, ref, child, get } from "firebase/database";
import { useAuth } from "./AuthContext";
// import PreviewModal from "./components/PreviewModal";
// import Ads from "./components/Ads";
// import ad from "./image/ad.mp4"
import { getDiscovery, getGenres, getDetails } from "./Themoviedb";
import { getExistingList } from "./firebaseConfig";
import Skeleton from "./components/Skeleton";
import { isMobile } from "./components/mobile";

import MobileHeader from "./components/MobileHeader";

function recommendMoviesBasedOnSelection(availableMovies, selectedMovies) {
    // Collect genres and rating ranges from the selected movies
    const selectedGenres = [...new Set(selectedMovies
        .filter(movie => {
            if (movie.genres) {
                return movie.genres.length > 0
            }
            return false
        })
        .map(movie => {
            if (movie.genres) {
                return movie.genres[0].id
            }

            return []
        }))];  // Collects only the first genre's id for now
    const minSelectedRating = Math.min(...selectedMovies.map(movie => movie.vote_average || 0));

    // Recommend movies from availableMovies that match the genres or have similar ratings
    return availableMovies.filter(movie => {
        // Check if genres and vote_average exist
        if (!movie || !movie.genres || (movie.genres && movie.genres.length === 0) || typeof movie.vote_average === 'undefined') {
            return false;
        }

        // Check if at least one genre matches the selected genres
        const genreMatch = movie.genres.some(genre => selectedGenres.includes(genre.id));
        const ratingMatch = movie.vote_average >= minSelectedRating;  // Rating comparison

        return genreMatch && ratingMatch;
    });
}



function Browse() {
    const [isLoading, setIsLoading] = useState(true)
    const [availableContents, setAvailableContents] = useState([])
    const [contents, setContents] = useState([]);
    const location = useLocation()
    const navigate = useNavigate()

    const { currentUser } = useAuth()
    const library = currentUser?.library
    const username = currentUser?.username || "No Name"

    const [continueWatching, setContinueWatching] = useState([])
    const [myList, setMyList] = useState([])
    const [discoverMovies, setDiscoverMovies] = useState([])
    const [discoverTVs, setDiscoverTVs] = useState([])
    const [genres, setGenres] = useState([])
    const [now_playing, setNow_playing] = useState(null)


    useEffect(() => {
        const avcts = async () => {
            const data = await getExistingList()
            const list = data.prefixes.map(d => d.name)
            setAvailableContents(list)
        }

        avcts()
    }, [])


    useEffect(() => {

        const now_playing = async () => {
            getDiscovery("movie", 2).then(async data => {
                const details = await getDetails('movie', data.results[1].id)
                // console.log(details);
                setNow_playing(details)
            })
        }

        const movies = async () => {
            try {
                const data = await getDiscovery("movie");  // Wait for discovery API response
                const results = await Promise.all(
                    data.results.map(async (result) => {
                        return availableContents.includes(String(result.id)) ? result : null
                    })
                );

                // Filter out the null values (movies that don't exist in storage)
                const filteredResults = results.filter(result => result !== null);
                setDiscoverMovies(filteredResults);  // Set the filtered movies

            } catch (error) {
                console.error("Error fetching movies: ", error);
            }
        };

        const tvs = async () => {
            try {
                const data = await getDiscovery("tv");  // Wait for discovery API response

                const results = await Promise.all(
                    data.results.map(async (result) => {
                        return availableContents.includes(String(result.id)) ? result : null
                    })
                );

                // Filter out the null values (shows that don't exist in storage)
                const filteredResults = results.filter(result => result !== null);
                setDiscoverTVs(filteredResults);  // Set the filtered TV shows

            } catch (error) {
                console.error("Error fetching TV shows: ", error);
            }
        };

        const genres = async () => {
            getGenres("movie").then(data => {
                setGenres(data)
            })
        }
        now_playing()
        tvs()
        movies()
        genres()

    }, [availableContents])


    useEffect(() => {
        async function getContents() {
            if (library && library.myWatch) {

                const watched_contents = await Promise.all(
                    Object.keys(library.myWatch).map(async id => {
                        const data = await getDetails('movie', id);
                        return data;
                    })
                );


                setContinueWatching(watched_contents)
            } else if ((currentUser && !library) || (currentUser && library && !library.myList)) {
                navigate("/select")
            }
        }

        getContents();
    }, [currentUser, library, navigate]);

    useEffect(() => {
        async function getContents() {
            if (library && library.myList) {

                const list_contents = await Promise.all(
                    library.myList.map(async id => {
                        const data = await getDetails('movie', id);
                        return data;
                    })
                );

                setMyList(list_contents);
            } else if ((currentUser && !library) || (currentUser && library && !library.myList)) {
                navigate("/select")
            }
        }

        getContents();
    }, [currentUser, library, navigate]);

    useEffect(() => {
        async function getContents() {
            const contents = await Promise.all(
                availableContents.map(async id => {
                    const data = await getDetails('movie', id);
                    return data;
                })
            );

            if (myList[0]) {
                const filtered = recommendMoviesBasedOnSelection(contents, myList)
                setContents(filtered);

            } else {
                setContents(contents);

            }



        }

        getContents();
    }, [availableContents, myList]);




    useEffect(() => {
        if (now_playing && contents) {
            setIsLoading(false)
        }
    }, [now_playing, contents])






    function sanitizeGenre(genre) {
        return genre.replace(/\s+/g, '').toLowerCase();
    }

    const bodyRef = useRef()



    return (
        <>
            {isMobile() !== 0 && (
                <MobileHeader bodyRef={bodyRef} title={"Browse"} menu={["For You", "Trending", "My List"]} defaultMenu={0} />
            )}
            {isLoading ? <Skeleton /> : (
                <div className="Skeleton">
                    <div className="right scroll mobileMarginTop" ref={bodyRef}>
                        {now_playing && (
                            <>
                                {isMobile() !== 0 && (
                                    <div style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original/${now_playing?.backdrop_path})`, backgroundSize: '5000%', backgroundPosition: 'center', zIndex: -1, filter: 'blur(200px)', width: 'calc91(00vw - 20px)', minHeight: '500px', position: 'relative', left: '0px' }}></div>

                                )}

                                <div className="largeBackdrop pannels mobileCenter" style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original/${now_playing?.backdrop_path})`, marginTop: isMobile() === 0 ? '100px' : '-400px' }}>
                                    <div className="mobileCenter">
                                        {now_playing?.images.logos[0] ? (
                                            <img src={`https://image.tmdb.org/t/p/original/${now_playing?.images.logos[0]?.file_path}`} alt={now_playing?.name} className="logo" />
                                        ) : (
                                            <div style={{ margin: "auto auto 0px 10px" }}>
                                                <h1>{now_playing?.name}</h1>
                                            </div>
                                        )}
                                        <div className="row" style={{ marginTop: "30px" }}>
                                            <Link className="button" style={{ textDecoration: 'none', fontSize: '13.33333px' }} to={`/watch/${now_playing?.id}`}>
                                                <ion-icon name="play"></ion-icon>
                                                Play
                                            </Link>
                                            <Link className="button" style={{ textDecoration: 'none', fontSize: '13.33333px' }} to={`/movie/${now_playing?.id}`} state={{ backgroundLocation: location }}>
                                                <ion-icon name="information-circle-outline"></ion-icon>
                                                More Info
                                            </Link>
                                        </div>
                                        <p className="overview mediumOverview">
                                            {now_playing?.overview}
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}

                        {continueWatching[0] && (
                            <>
                                <HrScroll array={continueWatching} title={`Continue Watching for ${username}`} />

                            </>
                        )}


                        {myList[0] && (
                            <>
                                <HrScroll array={myList} title="My List" />

                            </>
                        )}




                        {/* Discovery */}
                        {discoverMovies[0] && (
                            <>
                                <HrScroll array={discoverMovies} title="Discovery Movies" />
                            </>
                        )}

                        {discoverTVs[0] && (
                            <>
                                <>
                                    <HrScroll array={discoverMovies} title="Discovery TVs" />
                                </>
                            </>
                        )}





                        {/* Render rows for each genre */}
                        {genres.map((genre, i) => {
                            const sanitizedId = sanitizeGenre(genre.name + i);
                            const filteredContents = contents.filter(content => {
                                if (content && content.genres) {
                                    return content.genres.some(g => g.id === genre.id)
                                }

                                return []
                            }
                            );

                            // console.log(filteredContents);

                            // Only render the genre if there are contents
                            if (filteredContents.length > 0) {
                                return (

                                    <HrScroll array={filteredContents} title={genre.name} key={sanitizedId} />

                                );
                            }
                            return null; // Don't render anything if there are no contents
                        })}
                    </div>
                    {/* {location.hash.length !== 0 && (
                <PreviewModal id={location.hash.replace("#", "")} />
            )} */}
                </div >
            )
            }
        </>


    );
}


export function HrScroll({ array = [], title = "untitles" }) {

    const location = useLocation()


    function scrollNext(rowIndex) {
        const rowElement = document.querySelector(`#${rowIndex}`);
        if (rowElement) {
            const firstChild = rowElement.firstChild;
            if (firstChild) {
                rowElement.scrollBy({ left: (firstChild.clientWidth + 14) * 2, behavior: 'smooth' });
            }
        }
    }

    function scrollPrev(rowIndex) {
        const rowElement = document.querySelector(`#${rowIndex}`);
        if (rowElement) {
            const firstChild = rowElement.firstChild;
            if (firstChild) {
                rowElement.scrollBy({ left: (firstChild.clientWidth + 14) * -2, behavior: 'smooth' });
            }
        }
    }

    const id = title.replace(/\s+/g, '-').toLowerCase()

    return (
        <>
            <div className="colum" style={{ marginBottom: "10px" }}>
                <div className="row" style={{ padding: '0px 10px' }}>
                    <b className="RowTitle">{title}</b>
                    <button className="button square" onClick={() => scrollPrev(id)}>
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button className="button square" onClick={() => scrollNext(id)}>
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                </div>
                <div style={{ overflow: "hidden" }}>
                    <div className="row" id={id} style={{ overflowX: "scroll", paddingBottom: "20px", display: "flex", gap: '0px' }}>
                        {array.map((content) => (
                            <Link
                                to={`/${content?.media_type}/${content?.id}`}
                                state={{ backgroundLocation: location }}
                                className="s_card card"
                                style={{
                                    marginLeft: '10px',
                                    backgroundImage: `url(https://image.tmdb.org/t/p/original/${content?.poster_path})`,
                                    // height: "250px",
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-start'
                                }}
                                key={id + content?.id}
                            >
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Browse;
