import React, { useEffect, useState } from "react";
import "./MovieSelection.css"; // Your CSS file
import { getDetails } from "./Themoviedb";
import { getExistingList, setMylist } from "./firebaseConfig";
import { useAuth } from "./AuthContext";
import { Navigate, useLocation } from "react-router-dom";



const MovieSelection = () => {
    const [availableContents, setAvailableContents] = useState([])
    const [selectedMovies, setSelectedMovies] = useState([]);
    const [contents, setContents] = useState([])
    const location = useLocation()
    const { account, currentUser, refreshAccount } = useAuth()

    useEffect(() => {
        const avcts = async () => {
            const data = await getExistingList()
            const list = data.prefixes.map(d => d.name)
            setAvailableContents(list)
        }

        avcts()
    }, [])

    const toggleMovieSelection = (movieId) => {
        if (selectedMovies.includes(movieId)) {
            setSelectedMovies(selectedMovies.filter((id) => id !== movieId));
        } else if (selectedMovies.length < 5) {
            setSelectedMovies([...selectedMovies, movieId]);
        }
    };

    useEffect(() => {
        async function getContents() {
            const contents = await Promise.all(
                availableContents.map(async id => {
                    const data = await getDetails('movie', id);
                    return data;
                })
            );

            setContents(contents);

        }

        getContents();
    }, [availableContents]);

    const handleSubmit = async () => {
        console.log(account)
        if (selectedMovies.length >= 3 && selectedMovies.length <= 5) {
            try {

                await setMylist(account.uid, currentUser.token, selectedMovies)

                // Once all are added, refresh the account

                await refreshAccount(account);
                console.log(currentUser.library);
            } catch (error) {
                console.error("Error adding movies or refreshing account:", error);
            }
        }
    };

    return (
        <div className="container">
            {currentUser && currentUser.library && currentUser.library.myList && <Navigate to={"/"} replace />}
            <h1>Select Your Favorite Movies</h1>
            <p>Select 3-5 movies to get personalized recommendations.</p>
            <div className="movies-grid">
                {contents.map((movie) => (
                    <div
                        key={movie.id}
                        className={`movie-item ${selectedMovies.includes(movie.id) ? "selected" : ""}`}
                        onClick={() => toggleMovieSelection(movie.id)}
                    >
                        <img src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`} alt={movie.title} />
                        <div className="overlay"></div>
                    </div>
                ))}
            </div>
            <button
                className="button"
                style={{ margin: " 25px auto" }}
                onClick={handleSubmit}
                disabled={selectedMovies.length < 3 || selectedMovies.length > 5}
            >
                Done
            </button>
        </div>
    );
};

export default MovieSelection;
