
function generateToken(length = 24) {
    let token = '';
    const characters = '0123456789abcdef';

    for (let i = 0; i < length; i++) {
        token += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return token;
}

export { generateToken }