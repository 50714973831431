import { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import BottomNavbar from './components/BottomNavbar';
import Login from './Login';
import SignUp from './Signup';
import NewUserPage from './CreateUser';
import Browse from './Browse';
import Movies from './Movies';
import TVs from './TVs';
import { AuthProvider } from './AuthContext'; // Assuming you are using AuthContext
// import PrivateRoute from './PrivateRoute'; // For protecting routes
// import Watch from './WatchPage';
import PreviewModal from './components/PreviewModal';
import PreviewModalTV from './components/PreviewModalTV';
import Mobile from './mobile';
import Profiles from './profiles';
import MovieSelection from './Startup';
import Watch from './Video';
import Search from './search';
import { isMobile } from './components/mobile';

import SharedContent from './SharedContent';



function AppContent() {
  const location = useLocation();
  const state = location.state;

  const [isStandalone, setIsStandalone] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleDisplayModeChange = (e) => {
      setIsStandalone(e.matches);
    };

    const matchMedia = window.matchMedia('(display-mode: standalone)');
    setIsStandalone(matchMedia.matches);

    matchMedia.addListener(handleDisplayModeChange);
    return () => matchMedia.removeListener(handleDisplayModeChange);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const [showNavBar, setShowNavBar] = useState(true);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    // Define the paths where navbar and header should be hidden
    const hiddenPaths = new Set([
      '/profiles',
      '/select',
      '/new-user',
      '/login',
      '/signup'
    ]);

    // Handle dynamic path for '/watch/:id'
    const hideForWatchPage = /^\/watch\/[^/]+$/.test(location.pathname);

    if (hiddenPaths.has(location.pathname) || hideForWatchPage) {
      setShowNavBar(false);
      setShowHeader(false);
    } else {
      setShowNavBar(true);
      setShowHeader(true);
    }
  }, [location.pathname]);


  // if (width < 767 && !isStandalone) {
  //   return <Mobile />
  // }


  return (
    <>
      {showHeader && isMobile() === 0 && <Header />}

      <Routes location={state?.backgroundLocation || location}>

        <Route path="/" element={<Browse />} />
        <Route path="/movies" element={<Movies />} />
        <Route path="/tvs" element={<TVs />} />
        <Route path="/search" element={<Search />} />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path='/profiles' element={<Profiles />} />
        <Route path="/new-user" element={<NewUserPage />} />
        <Route path="/select" element={<MovieSelection />} />
        <Route path="/watch/:id" element={<Watch />} />
        <Route path="/share/:token" element={<SharedContent />} />
        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route path="/movie/:id" element={<PreviewModal />} />
          <Route path="/tv/:id" element={<PreviewModalTV />} />
        </Routes>
      )}
      {(isMobile() !== 0 && showNavBar) && <BottomNavbar />}
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
