// AuthContext.js
import { createContext, useContext, useEffect, useState, useCallback } from "react";
import { auth } from "./firebaseConfig"; // Firebase config
import { onAuthStateChanged, signOut } from "firebase/auth"; // Firebase methods
import { getDatabase, ref, child, get } from "firebase/database";


// Create the AuthContext
const AuthContext = createContext();

// Export useAuth hook for easy access to context
export const useAuth = () => {
    return useContext(AuthContext);
};

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
    const [isLoggedin, setLoggedin] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [account, setAccount] = useState(null);
    const [loading, setLoading] = useState(true);


    const refreshAccount = useCallback((user) => {
        if (!user) {
            setLoggedin(false)
            return
        }
        const dbRef = ref(getDatabase());
        get(child(dbRef, `users/${user.uid}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const savedProfileTkn = localStorage.getItem('currentUser')
                setAccount(snapshot.val());
                if (savedProfileTkn) {
                    const currentUser = snapshot.val().profiles.find(profile => profile.token === savedProfileTkn)
                    if (currentUser) {
                        setCurrentUser(currentUser)
                    } else {
                        localStorage.removeItem('currentUser')
                        setCurrentUser(null)
                    }

                } else {
                    setCurrentUser(null)
                }
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }, [])



    useEffect(() => {
        // Listen for authentication state changes (login/logout)
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            refreshAccount(user)
            setLoggedin(true)
        });
        setLoading(false); // Once we know the user, stop loading

        // Cleanup listener on unmount
        return unsubscribe;
    }, [refreshAccount]);

    // Function to log out
    const logout = () => {
        localStorage.removeItem('currentUser')
        return signOut(auth);
    };

    // Context value to provide to the rest of the app
    const value = {
        isLoggedin,
        currentUser,
        account,
        setCurrentUser,
        refreshAccount,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children} {/* Only render children when loading is false */}
        </AuthContext.Provider>
    );
};
