import { useEffect, useState } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import PlinxLogo from './image/logo.png'; // Assuming the logo is located in the image folder
import { auth } from './firebaseConfig'; // Import Firebase Auth
import { signInWithEmailAndPassword } from 'firebase/auth'; // Firebase sign-in function
import { useAuth } from './AuthContext';

const fetchMovies = async (page) => {
    const url = `https://api.themoviedb.org/3/discover/movie?include_adult=false&include_video=false&language=en-US&page=${page}&sort_by=popularity.desc`;
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: 'Bearer YOUR_API_KEY' // Replace with your actual API key
        }
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [backdrops, setBackdrops] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate(); // useNavigate for redirection

    const { account } = useAuth()

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async () => {
        setLoading(true); // Set loading to true
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/"); // Navigate to home on success
        } catch (err) {
            switch (err.code) {
                case 'auth/email-already-in-use':
                    setError("Email already in use.");
                    break;
                case 'auth/invalid-email':
                    setError("Invalid email format, plinx@example.com.");
                    break;
                case 'auth/invalid-credential':
                    setError("Password or E-mail is incorrect");
                    break;
                default:
                    setError("An error occurred. Please try again.");
                // setError(err.code);

            }
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    useEffect(() => {
        const fetchMultiplePages = async () => {
            let allMovies = [];
            const totalPagesToFetch = 3; // Number of pages to fetch (adjust as needed)

            for (let page = 1; page <= totalPagesToFetch; page++) {
                try {
                    const data = await fetchMovies(page);
                    allMovies = [...allMovies, ...data.results];
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

            setBackdrops(allMovies.map(i => i.backdrop_path));
        };

        // fetchMultiplePages();
    }, []);

    return (
        <div className="loginContainer">
            {/* <div className='movieGrid'>
                {Array.from({ length: 6 }).map((_, i) => (
                    <>
                        <div className='movieRow' style={{ animation: `goleft ${100 * i}s infinite linear` }} key={i}>
                            {[...backdrops.slice(0, 13), ...backdrops.slice(0, 13)].map((url, j) => (
                                <div className='movieGridItem' key={j}>
                                    <div style={{
                                        backgroundImage: `url(https://image.tmdb.org/t/p/original/${url})`,
                                        height: "100%",
                                        width: "100%",
                                        backgroundSize: "cover"
                                    }}></div>
                                </div>
                            ))}
                        </div>
                        <div className='movieRow' style={{ animation: `goright ${100 * i}s infinite linear` }} key={i}>
                            {[...backdrops.slice(13, 26), ...backdrops.slice(13, 26)].map((url, j) => (
                                <div className='movieGridItem' key={j}>
                                    <div style={{
                                        backgroundImage: `url(https://image.tmdb.org/t/p/original/${url})`,
                                        height: "100%",
                                        width: "100%",
                                        backgroundSize: "cover"
                                    }}></div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div> */}

            <div className="box">
                <div className="logoDiv">
                    <img src={PlinxLogo} alt="Plinx Logo" className="plinxLogo" />
                </div>

                <div className="inpuDiv">
                    <input
                        type="email"
                        placeholder="E-mail"
                        className="loginInput"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="inpuDiv">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className="loginInput"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="checkbox"
                        id="seePass"
                        style={{ display: 'none' }}
                        onChange={togglePasswordVisibility}
                    />
                    <label htmlFor="seePass">
                        <ion-icon
                            name={showPassword ? "eye-off" : "eye"}
                            style={{ color: "#fff", cursor: "pointer" }}
                        />
                    </label>
                </div>



                {error && <div style={{ color: 'red', fontSize: "11px" }}>{error}</div>}


                <div className='extraLink'>
                    <a href='/forgot'>I forgot my password</a>
                </div>

                <div className="buttonDiv">
                    <button className="loginButton" onClick={handleLogin} disabled={loading}>
                        {loading ? 'Loading...' : 'Login'}
                    </button>
                    <Link to='/signup' className="signupButton">Sign up</Link>
                </div>
            </div>
            {account && (<Navigate to="/" replace />)}
        </div>
    );
}

export default Login;
