import { useState, useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import logo from '../image/logo.png'; // Assuming you have a logo
import { useAuth } from '../AuthContext'; // Import the AuthContext


function MobileHeader({ bodyRef, title = "Title", menu = [], defaultMenu = null, component = () => { return <></> } }) {
    const [isBlurred, setIsBlurred] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0); // Tracks total scroll position
    const [prevScrollHeight, setPrevScrollHeight] = useState(0); // Tracks previous scroll height
    const [scrollDelta, setScrollDelta] = useState(0); // Tracks delta between scroll events
    const navigate = useNavigate();
    const location = useLocation()
    const { currentUser, account } = useAuth(); // Consume auth context


    useEffect(() => {
        const handleScroll = () => {
            if (scrollHeight > 0) {
                setIsBlurred(true);
            } else {
                setIsBlurred(false);
            }

            // Calculate delta scroll
            const delta = scrollHeight - prevScrollHeight;
            setScrollDelta(delta);

            // Update previous scroll height
            setPrevScrollHeight(scrollHeight);
        };

        handleScroll();

    }, [scrollHeight, prevScrollHeight]);

    const scrollArea = bodyRef.current

    useEffect(() => {
        if (scrollArea) {
            scrollArea.onscroll = () => {
                setScrollHeight(scrollArea.scrollTop);
            };
        }

        // console.log(bodyRef);
    }, [scrollArea]);

    return (
        <header
            className={`mobileHeader ${isBlurred ? 'blur' : ''}`}
            style={{
                position: 'fixed',
                top: 0,
                width: 'calc(100% - 40px)',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                padding: '10px 20px',
                background: isBlurred ? '#0005' : 'transparent',
                backdropFilter: isBlurred ? 'blur(10px)' : 'none',
                transition: 'background 0.3s ease, backdropFilter 0.3s ease, border 0.3s ease',
                borderBottom: isBlurred ? '1px solid #fff2' : '0px solid #0000'
            }}
        >
            {!account && <Navigate to={"/login"} />}
            {account && !currentUser && <Navigate to={"/profiles"} />}

            {/* Animating UI */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >

                {/*  User Intro */}
                <div style={{ display: "flex", gap: "10px" }}>
                    <div className='avatar medium' onClick={() => navigate('/profiles')} style={{ width: isBlurred && "0px", height: isBlurred && "0px", transition: "0.2s", backgroundImage: `url(${currentUser?.profileUrl})` }}></div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <span style={{ fontWeight: "bold", fontSize: isBlurred ? "0px" : "16px", transition: "0.2s" }}>{currentUser?.username}</span>
                        <span style={{ fontSize: isBlurred ? "18px" : "11px", fontWeight: isBlurred ? 800 : 600, transition: "0.2s" }}>{title}</span>
                    </div>
                </div>

                {/*custom component */}
                {component}

                {/* Filters */}
                {menu[0] && (
                    <div className="filters" style={{ display: 'flex', gap: '15px', marginTop: "10px", opacity: isBlurred ? 1 : 0, height: isBlurred ? "26px" : "0px", transition: "0.2s", overflow: "hidden" }}>
                        {menu.map((i, index) => {
                            let path = `#${i.replace(/\s/g, '-').toLocaleLowerCase()}`
                            if (!isNaN(defaultMenu) && index === defaultMenu) {
                                path = ""
                            }
                            return (
                                <button key={i} className='outline-button' onClick={() => navigate(path)} style={{ backgroundColor: location.hash === path && '#fff5' }}>{i}</button>
                            )
                        })}
                    </div>
                )}

            </div>
        </header>
    );
}

export default MobileHeader;
