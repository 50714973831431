export default class Shortcut {
    constructor({ key = "", mouse = "", altKey = false, shiftKey = false, action = () => { } }, document = window.document) {
        this.key = key; // Key (e.g., "q")
        this.mouse = mouse; // Mouse action (e.g., "wheel", "left click")
        this.altKey = altKey;
        this.shiftKey = shiftKey;
        this.action = action;
        this.document = document;

        this.bind();
    }

    // Checks if the event matches the keyboard shortcut configuration
    matchesKeyEvent(event) {
        return event.key === this.key &&
            event.altKey === this.altKey &&
            event.shiftKey === this.shiftKey;
    }

    // Checks if the event matches the mouse shortcut configuration
    matchesMouseEvent(event) {
        return (this.mouse === "wheel" && event.type === "wheel") ||
            (this.mouse === "left click" && event.type === "click" && event.button === 0) ||
            (this.mouse === "right click" && event.type === "contextmenu") ||
            (this.mouse === "double click" && event.type === "dblclick");
    }

    // Executes the action with metadata
    execute(metadata) {
        if (typeof this.action === "function") {
            this.action(metadata);
        }
    }

    // Binds the event listeners based on the shortcut configuration
    bind() {
        if (this.key) {
            this.document.addEventListener("keydown", this.handleKeyEvent);
        }

        if (this.mouse) {
            const mouseEvent = this.mouse === "double click" ? "dblclick" :
                this.mouse === "right click" ? "contextmenu" : "click";
            this.document.addEventListener(mouseEvent, this.handleMouseEvent);

            if (this.mouse === "wheel") {
                this.document.addEventListener("wheel", this.handleMouseEvent);
            }
        }
    }

    // Unbinds the event listeners
    unbind() {
        this.document.removeEventListener("keydown", this.handleKeyEvent);
        const mouseEvent = this.mouse === "double click" ? "dblclick" :
            this.mouse === "right click" ? "contextmenu" : "click";
        this.document.removeEventListener(mouseEvent, this.handleMouseEvent);

        if (this.mouse === "wheel") {
            this.document.removeEventListener("wheel", this.handleMouseEvent);
        }
    }

    // Handler for keyboard events
    handleKeyEvent = (event) => {
        if (this.matchesKeyEvent(event)) {
            const metadata = this.buildEventMetadata(event, "keydown");
            this.execute(metadata);
        }
    }

    // Handler for mouse events
    handleMouseEvent = (event) => {
        if (this.matchesMouseEvent(event)) {
            const metadata = this.buildEventMetadata(event, event.type);
            this.execute(metadata);
        }
    }

    // Constructs metadata for event handling
    buildEventMetadata(event, eventType) {
        return {
            type: eventType,
            key: event.key || null,
            code: event.code || null, // Add code for more precise key handling
            altKey: event.altKey,
            shiftKey: event.shiftKey,
            ctrlKey: event.ctrlKey || false,
            metaKey: event.metaKey || false,
            mouseX: event.clientX || null,
            mouseY: event.clientY || null,
            wheelDelta: event.deltaY || null,
            button: event.button || null,
        };
    }
}
