import './style.css';




function Skeleton() {
    return (
        <div className="Skeleton">
            {/* <div className="left pannels animateFlat"></div> */}
            <div className="right pannels">
                <div className="large pannels animateFlat"></div>
                <div className="row" style={{ overflow: 'hidden' }}>
                    <div className="s_card animateFlat"></div>
                    <div className="s_card animateFlat"></div>
                    <div className="s_card animateFlat"></div>
                </div>
            </div>
        </div>
    );
}

export default Skeleton;
