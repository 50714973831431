import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation, useSearchParams } from 'react-router-dom';
import { getDetails, getRecommendations, getSeason } from '../Themoviedb';
import ShareModal from './Share';


function PreviewModalTV() {
    const navigate = useNavigate()
    const location = useLocation()

    const [content, setContent] = useState(null)
    const [alikeContents, setAlikeContents] = useState([])

    const [sharing, setSharing] = useState(false)


    const { id } = useParams();

    useEffect(() => {

        async function fetchContent() {
            const content = await getDetails('tv', id);
            setContent(content)

            const alikeContents = await getRecommendations('tv', id);
            setAlikeContents(alikeContents)
        }

        fetchContent()
    }, [id])

    console.log(content)




    return (
        <>
            <div className="modal-overlay">
                <div className="modal-container" onClick={e => e.stopPropagation()}>
                    <div className='header' style={{ borderBottom: '1px solid #ffffff10', marginBottom: '20px' }}>
                        <h2 style={{ margin: '10px', padding: '0px', textAlign: 'center', fontSize: '18px' }}>{content ? content.name : "Loading..."}</h2>
                    </div>
                    <span className="close-button" onClick={() => navigate(location?.state?.backgroundLocation?.pathname || "/")}><ion-icon name="close-outline" style={{ color: "#fff" }}></ion-icon></span>
                    <div className="modal-content movie-preview" style={{ gap: '20px' }}>

                        {content && (
                            <>
                                <div className="content-thumbnail" style={{
                                    backgroundImage: `url(https://image.tmdb.org/t/p/original/${content?.backdrop_path})`,
                                    width: content?.content_type === "Song" && "300px",
                                    margin: content?.content_type === "Song" && "0px auto",
                                }}>
                                    {content?.images?.logos[0] ? (
                                        <div style={{ margin: "auto auto 0px 10px" }}>
                                            <img src={`https://image.tmdb.org/t/p/original/${content?.images.logos[0].file_path}`} alt="MovieName" className="logo" />
                                        </div>
                                    ) : (
                                        <div style={{ margin: "auto auto 0px 10px" }}>
                                            <h1>{content?.name}</h1>
                                        </div>
                                    )}


                                    <div className="row" style={{ margin: "auto auto 10px 10px" }}>
                                        <button className="button" onClick={() => navigate(`/watch/${id}`)}>
                                            <ion-icon name="play"></ion-icon>
                                            Play
                                        </button>
                                        <div className="square expandable" style={{ padding: '0px', display: 'flex', backgroundColor: '#fff1', backdropFilter: 'blur(10px)', borderRadius: '20px' }} >
                                            <button className="nbbutton square addToButton">
                                                <ion-icon name="add"></ion-icon>
                                            </button>

                                            <button className="nbbutton square likeButton">
                                                <ion-icon name="heart-outline"></ion-icon>
                                            </button>

                                            <button className="nbbutton square shareButton" onClick={() => {
                                                setSharing(true)
                                            }}>
                                                <ion-icon name="arrow-redo-outline"></ion-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {content?.overview && (
                                    <div style={{ textAlign: 'left', backgroundColor: "#00000050", padding: "20px", borderRadius: "20px", margin: '0px' }}>
                                        <div className='header' style={{ borderBottom: '1px solid #ffffff10', paddingBottom: '5px', marginBottom: '10px' }}>
                                            <b style={{ padding: '0px' }}>Overview</b>
                                        </div>
                                        {content ? content.overview : "Loading..."}
                                    </div>
                                )}

                                <div style={{ textAlign: 'left', backgroundColor: "#00000050", padding: "20px", borderRadius: "20px", margin: '0px' }}>
                                    <EpisodesColumn id={`seasons-${content?.id}`} series_id={content?.id} seasons={content.seasons} />
                                </div>

                                <div style={{ textAlign: 'left', backgroundColor: "#00000050", padding: "20px", borderRadius: "20px", margin: '0px' }}>
                                    <ContentMiniRow id={`alike-${content?.id}`} array={alikeContents} title={`More like ${content?.name}`} />
                                </div>

                            </>
                        )}

                    </div>
                </div>
            </div>
            {sharing && <ShareModal content={content} onClose={() => setSharing(false)} />}

        </>
    );
}


function ContentMiniRow({ id, title, array }) {


    function scrollNext(rowIndex) {
        const rowElement = document.querySelector(`#${rowIndex}`);
        if (rowElement) {
            const firstChild = rowElement.firstChild;
            if (firstChild) {
                rowElement.scrollBy({ left: (firstChild.clientWidth + 14) * 2, behavior: 'smooth' });
            }
        }
    }

    function scrollPrev(rowIndex) {
        const rowElement = document.querySelector(`#${rowIndex}`);
        if (rowElement) {
            const firstChild = rowElement.firstChild;
            if (firstChild) {
                rowElement.scrollBy({ left: (firstChild.clientWidth + 14) * -2, behavior: 'smooth' });
            }
        }
    }

    const location = useLocation()


    return (
        <>
            <div className='header' style={{ borderBottom: '1px solid #ffffff10', paddingBottom: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <b style={{ padding: '0px', marginRight: 'auto' }}>{title}</b>

                <button className="button square" onClick={() => scrollPrev(id)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </button>
                <button className="button square" onClick={() => scrollNext(id)}>
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
            </div>
            <div className="row" id={id} style={{ overflowX: "scroll", paddingBottom: "20px", display: "flex" }}>
                {array.map((content) => (
                    <Link
                        to={`/${content.media_type}/${content.id}`}
                        state={location.state}
                        className="s_card card"
                        style={{
                            minWidth: '150px',
                            minHeight: '225px',
                            backgroundImage: `url(https://image.tmdb.org/t/p/original/${content.poster_path})`,
                            height: content.content_type === "Song" && "250px",
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-start'
                        }}
                        key={content.id}
                    >
                    </Link>
                ))}
            </div>
        </>
    )
}


function EpisodesColumn({ id, series_id, seasons = [] }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [current_season, setSeason] = useState(null);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [current_season_number, setCurrentSeasonNumber] = useState(seasons[0]?.season_number);
    const [numberOfEpisodes, setNumberOfEpisodes] = useState(0)

    const [searchParams] = useSearchParams();
    const querySeason = searchParams.get('season');

    // Update current_season_number based on query parameters
    useEffect(() => {
        if (querySeason) {
            setCurrentSeasonNumber(Number(querySeason));
        } else if (seasons.length > 0) {
            setCurrentSeasonNumber(seasons[0].season_number);
        }
        setDropdownOpened(false)
    }, [querySeason, seasons]);

    // Fetch the selected season's data
    useEffect(() => {
        const fetchSeason = async () => {
            if (series_id && current_season_number !== null) {
                const data = await getSeason(series_id, current_season_number);
                setSeason(data);
                setNumberOfEpisodes(data?.episodes.filter(e => e.still_path)?.length)
            }
        };
        fetchSeason();
    }, [series_id, current_season_number]);

    return (
        <>
            <div className='header' style={{ borderBottom: '1px solid #ffffff10', paddingBottom: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <b style={{ padding: '0px', marginRight: 'auto' }}>{numberOfEpisodes} Episodes</b>

                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '40px', height: '40px', position: 'relative', zIndex: 10 }}>
                    <button className="button" style={{ fontSize: '11px', fontWeight: 'bold' }} onClick={() => setDropdownOpened(!dropdownOpened)}>
                        {current_season?.name}
                        <ion-icon name="chevron-down-outline" style={{ transition: '0.1s', transform: `rotateX(${dropdownOpened ? 180 : 0}deg)`, marginLeft: 'auto' }}></ion-icon>
                    </button>

                    {dropdownOpened && (
                        <div className='blur' style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', backgroundColor: '#fff1', borderRadius: '20px' }}>
                            {seasons.map(season => (
                                <Link
                                    key={season.id}
                                    to={`/tv/${series_id}?season=${season.season_number}`}
                                    state={location.state}
                                    className="user"
                                    style={{
                                        fontSize: '11px', fontWeight: 'bold', justifyContent: 'start', transition: '0s', padding: '15px', height: 'unset', backgroundColor: season.id === current_season.id && '#fff', color: season.id === current_season.id && '#000'
                                    }}
                                >
                                    {season.name}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="column" id={id} style={{ overflowX: "scroll", paddingBottom: "20px", display: "flex" }}>
                {current_season?.episodes?.filter(e => e.still_path).map((content) => (
                    <Link className='result-item' key={content.id} to={`/watch/${content.id}`}>
                        <div style={{ height: '75px', aspectRatio: 16 / 9, backgroundColor: '#fff1', borderRadius: '10px', backgroundImage: `url(https://image.tmdb.org/t/p/original/${content.still_path})`, backgroundSize: 'cover' }}></div>
                        <div style={{ flex: 1, padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <b>{content.title || content.name}</b>
                            <p className='shortOverview'>{content.overview}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
}

export default PreviewModalTV;
