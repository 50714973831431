import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getDatabase } from 'firebase/database';
import { ref, set } from "firebase/database"; // Firebase real-time database functions
import { generateToken } from './Token';

function NewUserPage() {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const db = getDatabase()
    const { account } = useAuth(); // Get the current logged-in user

    const profiles = account.profiles

    const handleCreate = async () => {
        setLoading(true);
        try {
            if (!username) {
                throw new Error("Username is required");
            }

            // Reference to the user's profiles in the real-time database
            const profileRef = ref(db, `users/${account.uid}/profiles/${profiles.length}`);
            const token = generateToken()

            const newProfile = {
                username,
                token,
                profileUrl: "https://www.kaericature.com/wp-content/uploads/2023/05/custom_avatar3_3d-300x300.jpg"
            }
            // Create a new profile with a unique key under the current user
            await set(profileRef, newProfile)

            // On success, navigate to home
            localStorage.setItem('currentUser', token)
            window.location.pathname = "/"

        } catch (err) {
            setError(err.message); // Display error message
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="loginContainer">

            <div className="box">
                <div style={{ backgroundColor: '#1234', borderRadius: '100px', height: '100px', width: '100px', margin: '20px auto' }} className='animate-border'>
                    <div style={{ height: '100%', width: '100%', backgroundColor: '#4324', borderRadius: '100px', backgroundSize: 'cover', backgroundImage: 'url(https://www.kaericature.com/wp-content/uploads/2023/05/custom_avatar3_3d-300x300.jpg)' }}></div>
                </div>

                <div className="inpuDiv">
                    <input
                        type="text"
                        placeholder="Username"
                        className="loginInput"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                {error && <div style={{ color: 'red', fontSize: "11px" }}>{error}</div>}

                <div className="buttonDiv">
                    <button className="loginButton" onClick={handleCreate} disabled={loading}>
                        {loading ? 'Loading...' : 'Create'}
                    </button>
                    <Link to='/profiles' className="signupButton">Cancel</Link>
                </div>
            </div>
        </div>
    );
}

export default NewUserPage;
