import { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
// import Ads from "./components/Ads";
// import ad from "./image/ad.mp4"
import { getDiscovery, getGenres, getDetails } from "./Themoviedb";
import { getExistingList } from "./firebaseConfig";
import MobileHeader from "./components/MobileHeader";
import Skeleton from "./components/Skeleton";
import Genres from "./components/Genres";
import { isMobile } from "./components/mobile";
import { HrScroll } from "./Browse";


function Movies() {
    const [isLoading, setIsLoading] = useState(true)
    const [availableContents, setAvailableContents] = useState([])
    const [contents, setContents] = useState([]);
    const location = useLocation()


    const [genres, setGenres] = useState([])
    const [now_playing, setNow_playing] = useState(null)

    useEffect(() => {
        const avcts = async () => {
            const data = await getExistingList()
            const list = data.prefixes.map(d => d.name)
            setAvailableContents(list)
        }

        avcts()
    }, [])


    useEffect(() => {

        const now_playing = async () => {
            getDiscovery("movie", 3).then(async data => {
                const details = await getDetails('movie', data.results[0].id)
                // console.log(details);
                setNow_playing(details)
            })
        }



        const genres = async () => {
            getGenres("movie").then(data => {
                setGenres(data)
            })
        }
        now_playing()
        genres()

    }, [])

    useEffect(() => {
        async function getContents() {
            const contents = await Promise.all(
                availableContents.map(async id => {
                    const data = await getDetails('movie', id);
                    return data;
                })
            );

            setContents(contents);

        }

        getContents();
    }, [availableContents]);



    useEffect(() => {
        if (now_playing && contents) {
            setIsLoading(false)
        }
    }, [now_playing, contents])




    function sanitizeGenre(genre) {
        return genre.replace(/\s+/g, '').toLowerCase();
    }

    const bodyRef = useRef()

    const [width, setWidth] = useState(window.innerWidth)

    window.onresize = () => {
        setWidth(window.innerWidth)
    }

    return (
        <>
            {isMobile() !== 0 && <MobileHeader bodyRef={bodyRef} title={"Movies"} menu={["All", "Categories"]} defaultMenu={0} />}
            {isLoading ? <Skeleton /> : (
                <div className="Skeleton">
                    {/* <div className="left pannels" style={{ overflow: 'hidden' }}>
            <Ads videoSrc={ad} href="/hello" />
        </div> */}
                    <div className="right scroll mobileMarginTop" ref={bodyRef}>
                        {now_playing && (
                            <>
                                {isMobile() !== 0 && (
                                    <div style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original/${now_playing?.backdrop_path})`, backgroundSize: '5000%', backgroundPosition: 'center', zIndex: -1, filter: 'blur(200px)', width: 'calc91(00vw - 20px)', minHeight: '500px', position: 'relative', left: '0px' }}></div>

                                )}

                                <div className="largeBackdrop pannels mobileCenter" style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original/${now_playing?.backdrop_path})`, margin: isMobile() === 1 ? '-400px auto 10px auto' : isMobile() === 2 ? '-400px 10px 10px 10px' : '-100px 10px 10px 10px', marginTop: isMobile() === 0 ? '100px' : '-400px' }}>
                                    <div className="mobileCenter">
                                        {now_playing?.images.logos[0] ? (
                                            <img src={`https://image.tmdb.org/t/p/original/${now_playing?.images.logos[0]?.file_path}`} alt={now_playing?.name} className="logo" />
                                        ) : (
                                            <div style={{ margin: "auto auto 0px 10px" }}>
                                                <h1>{now_playing?.name}</h1>
                                            </div>
                                        )}
                                        <div className="row" style={{ marginTop: "30px" }}>
                                            <Link className="button" style={{ textDecoration: 'none', fontSize: '13.33333px' }} to={`/watch/${now_playing?.id}`}>
                                                <ion-icon name="play"></ion-icon>
                                                Play
                                            </Link>
                                            <Link className="button" style={{ textDecoration: 'none', fontSize: '13.33333px' }} to={`/movie/${now_playing?.id}`} state={{ backgroundLocation: location }}>
                                                <ion-icon name="information-circle-outline"></ion-icon>
                                                More Info
                                            </Link>
                                        </div>
                                        <p className="overview mediumOverview">
                                            {now_playing?.overview}
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* Render rows for each genre */}
                        {genres.map((genre, i) => {
                            const sanitizedId = sanitizeGenre(genre.name + i);
                            const filteredContents = contents.filter(content =>
                                content && content.genres && content.genres.some(g => g.id === genre.id)
                            );

                            // console.log(filteredContents);

                            // Only render the genre if there are contents
                            if (filteredContents.length > 0) {
                                return (
                                    <HrScroll array={filteredContents} title={genre.name} key={sanitizedId} />
                                );
                            }
                            return null; // Don't render anything if there are no contents
                        })}
                    </div>
                    {/* {location.hash.length !== 0 && (
                <PreviewModal id={location.hash.replace("#", "")} />
            )} */}
                </div>
            )}
            {genres[0] && location.hash === '#categories' && <Genres genres={genres} location={location} />}
        </>
    );
}

export default Movies;
