import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

function Profiles() {
    const [x, setX] = useState(0);
    const scrollref = useRef();
    const navigate = useNavigate()
    const { account, setCurrentUser, currentUser, logout } = useAuth()
    const [users, setUsers] = useState([])

    useEffect(() => {
        if (account) {
            const profiles = account.profiles
            setUsers(profiles)
        }
    }, [account])

    useEffect(() => {
        const index = users.findIndex(i => currentUser?.token === i.token)
        scrollref.current.scrollLeft = Number((index * 110).toFixed())
    }, [currentUser, users])


    const getScale = (i) => {
        const profileWidth = 100
        const center = window.innerWidth / 2; // Center of the viewport
        const t = (center + 25) - x
        const elementPosition = t + (i * 100)
        const distanceFromCenter = Math.abs(center - elementPosition);



        // Adjust scale based on distance from the center
        if (distanceFromCenter < profileWidth / 2) {
            // setSelected(i)
            return 1;
        } else if (distanceFromCenter < profileWidth) {
            return 0.5;
        } else if (distanceFromCenter < profileWidth * 1.3) {
            return 0.2;
        } else {
            return 0;
        }
    };


    const switchProfile = (token, redirectPath) => {
        try {
            const user = account?.profiles?.find(profile => profile.token === token);
            if (!user) return;

            setCurrentUser(user);
            localStorage.setItem('currentUser', user.token);
            navigate(redirectPath.replace(/(%2F)/g, '/'));
        } catch (error) {
            console.error('Failed to switch profile:', error);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden', height: '100vh' }}>
            {!account && (<Navigate to="/login" replace />)}
            <span style={{ margin: 'auto', marginBottom: '0px', marginTop: '100px', fontWeight: 'bold', fontSize: '26px' }}>Who's watching?</span>
            <div
                ref={scrollref}
                onScroll={(e) => setX(e.target.scrollLeft)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    overflowX: 'auto', // Horizontal scrolling only
                    scrollBehavior: 'smooth', // Smooth scrolling
                    height: '200px',
                    width: '100vw',
                    flex: 1
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        overflow: 'visible',
                        gap: '10px',
                        padding: '0px calc(50vw - 50px)',
                    }}
                >
                    {users.map((user, index) => (
                        <div key={user.token} onClick={() => switchProfile(user.token, "/")} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                            <div
                                style={{
                                    height: '100px',
                                    width: '100px',
                                    borderRadius: '100px',
                                    backgroundColor: '#fff8',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transform: `scale(${getScale(index)})`, // Apply dynamic scaling
                                    transition: 'transform 0.3s ease', // Smooth scale transition
                                    // transition: 'transform 0.5s ease', // Smooth scale transition
                                    backgroundImage: `url(${user.profileUrl})`,
                                    backgroundSize: 'cover',
                                    position: 'relative',
                                    zIndex: getScale(index).toFixed()
                                }}
                            >
                                <span style={{ fontSize: '24px' }}></span>
                            </div>
                            <span style={{ color: 'white', marginTop: '50px', fontWeight: 'bold', transition: ' 0.3s', opacity: getScale(index) }}>{user.username}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="row_column" style={{ margin: 'auto', marginTop: '0px', marginBottom: '100px', display: 'flex', gap: '20px', width: '80vw' }}>
                <Link to={"/new-user"} className="button" style={{ backgroundColor: "#fff", color: "black", fontWeight: 'bold', textDecoration: 'none' }}>Create</Link>
                <Link to={`/login`} onClick={logout} style={{ textDecoration: "none" }} className="button">Log out</Link>
            </div>
        </div>
    );
}

export default Profiles;
