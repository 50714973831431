import { getFunctions, httpsCallable } from "firebase/functions";

export async function callSetCustomClaimsFunction(uid, token) {
    const functions = getFunctions();
    const setCustomClaims = httpsCallable(functions, 'setCustomClaims');

    try {
        const result = await setCustomClaims({ uid, token });
        return result.data;  // Response from the Firebase Function
    } catch (error) {
        console.error("Error setting custom claims:", error);
        throw error;
    }
}
