import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { getDatabase, ref, set } from "firebase/database";
import { externalId } from '../Themoviedb';

const generateToken = () => {
    // Generate a token based on the user or some unique data
    return btoa(new Date().getTime());
};

const createLink = () => {
    const token = generateToken();
    const shareableLink = `${window.location.origin}/share/${token}`;
    return { link: shareableLink, token };
};

function ShareModal({ content, onClose }) {
    const [shareableLink, setShareableLink] = useState(null);
    const [copied, setCopied] = useState(false);
    const [freePass, setFreePass] = useState(false);
    const [error, setError] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false); // Track the generation status

    const [imdb_id, set_imdb_id] = useState("")

    useEffect(() => {
        async function getIds() {
            const imdb_id = await externalId(content.media_type, content.id)

            set_imdb_id(imdb_id.imdb_id)
        }

        getIds()
    }, [content])

    console.log('modal', content)

    const { account, currentUser } = useAuth();

    const handleGenerateLink = () => {
        setIsGenerating(true); // Set generation status to true

        const { link, token } = createLink();

        async function postLink() {
            const db = getDatabase();

            const data = {
                contentId: content.id,
                freePass: freePass,
                expires: Date.now() + (86400 * 3 * 1000), // Expire in 3 days (86400 seconds/day)
                imdb_id: imdb_id,
                isActive: true,
                sharedAccountId: account.uid,
                sharedProfileToken: currentUser.token,
            };

            try {
                await set(ref(db, 'shares/' + token), data);
                setShareableLink(link);
                setError(null); // Clear any previous error if successful
            } catch (error) {
                setError('Failed to generate shareable link. Please try again.');
            } finally {
                setIsGenerating(false); // Reset generation status
            }
        }

        postLink();
    }

    const handleCopyLink = () => {
        if (shareableLink) {
            navigator.clipboard.writeText(shareableLink)
                .then(() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 3000); // Reset "copied" status after 3 seconds
                })
                .catch(err => console.error('Failed to copy link: ', err));
        }
    };

    return (
        <>
            <div className="modal-overlay">
                <div className="modal-container" style={{ height: 'auto', width: '400px' }} onClick={e => e.stopPropagation()}>
                    <div className='header' style={{ borderBottom: '1px solid #ffffff10', marginBottom: '20px' }}>
                        <h2 style={{ margin: '10px', padding: '0px', textAlign: 'center', fontSize: '18px' }}>
                            {content ? (content.title || content.name) : "Sharing"}
                        </h2>
                    </div>
                    <span className="close-button" onClick={onClose}>
                        <ion-icon name="close-outline" style={{ color: "#fff" }}></ion-icon>
                    </span>
                    <div className="modal-content" style={{ gap: '20px', height: 'auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {error && (
                                <p style={{ textAlign: 'left', margin: '0px', padding: '0px', color: 'red' }}>
                                    {error}
                                </p>
                            )}
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <input
                                    value={shareableLink || ""}
                                    readOnly
                                    className='button'
                                    style={{ flex: 1 }}
                                />
                                <button
                                    className='button'
                                    onClick={shareableLink ? handleCopyLink : handleGenerateLink}
                                    disabled={isGenerating} // Disable button while generating
                                >
                                    {isGenerating ? "Generating..." : shareableLink ? "Copy Link" : "Generate Link"}
                                </button>
                            </div>

                            {copied && (
                                <p style={{ textAlign: 'left', margin: '0px', padding: '0px', color: "green" }}>
                                    Link copied to clipboard!
                                </p>
                            )}

                            {shareableLink ? (
                                <p style={{ textAlign: 'left', color: "#555" }}>
                                    <span style={{ fontWeight: "bold", color: "#09f" }}>Upcoming:</span> You will be able to watch content with your friends using a shared link. <br />
                                    Keep in mind; this is not now available.
                                </p>
                            ) : (
                                <>
                                    <input type='checkbox' id='freepass' onChange={(e) => setFreePass(e.target.checked)} style={{ display: 'none' }} />
                                    <label style={{ display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }} htmlFor='freepass'>
                                        <ion-icon name={freePass ? "checkmark-circle" : "ellipse-outline"} style={{ color: freePass ? "#fff" : "#555" }}></ion-icon>
                                        FreePass?
                                    </label>
                                    <p style={{ textAlign: 'left', opacity: 0.5 }}>
                                        FreePass allows anyone with this link to watch this content without sign-in or a subscription. <br />
                                        But you won't be able to ban them from using this link.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShareModal;
