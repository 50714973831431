const apiKey = "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIwYjFiMzc0YzlkYzUxNjg1MzcxMjZlNjg2M2M0YzI1NCIsIm5iZiI6MTcyNzE5MjkwNC4zMDQyMzIsInN1YiI6IjYyOTJhMTQzZjEwYTFhMDA2NzE0NTlmMSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.Lz4peLE1pI9uYk4ZAa0j3BbxfsLvvXYJ3NHXbrejmP0"

async function getGenres(type) {
    const validTypes = ['movie', 'tv'];

    // Check for valid type input
    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie" or "tv".`);
        return;
    }

    const url = `https://api.themoviedb.org/3/genre/${type}/list?language=en`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => { return (json.genres) })
        .catch(err => console.error('Error: ' + err));
}


async function getDiscovery(type, page = 1, limit = 20) {
    const validTypes = ['movie', 'tv'];

    // Check for valid type input
    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie" or "tv".`);
        return;
    }

    const url = `https://api.themoviedb.org/3/discover/${type}?page=${page}&limit=${limit}`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(data => {
            const json = data

            const list = data.results.map(r => {
                r['media_type'] = type
                return r
            })

            json['results'] = list

            return (json)
        })
        .catch(err => console.error('Error: ' + err));
}

async function getNowPlaying(page) {


    const url = `https://api.themoviedb.org/3/movie/now_playing?language=en-US&page=${page}`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => { return (json?.results) })
        .catch(err => console.error('Error: ' + err));
}

async function getTrending(page, type = 'all') {
    const validTypes = ['movie', 'tv', 'all'];

    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie" or "tv".`);
        return;
    }

    const url = `https://api.themoviedb.org/3/trending/${type}/day?language=en-US&page=${page}`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => { return (json?.results) })
        .catch(err => console.error('Error: ' + err));
}

async function getImages(type, id) {
    const validTypes = ['movie', 'tv'];

    // Check for valid type input
    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie" or "tv".`);
        return;
    }

    const url = `https://api.themoviedb.org/3/${type}/${id}/images?language=en`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => { return (json) })
        .catch(err => console.error('Error: ' + err));
}



async function getDetails(type, id) {
    const validTypes = ['movie', 'tv', 'multi'];

    // Check for valid type input
    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie", "tv" or "multi.`);
        return;
    }


    const url = `https://api.themoviedb.org/3/${type}/${id}?language=en-US`;
    const images = await getImages(type, id)


    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => {
            json['images'] = images
            json['media_type'] = type
            json['genre'] = json.genres[0].id
            return (json)
        })
        .catch(err => console.error('Error: ' + err));
}



async function getSimilar(type, id) {
    const validTypes = ['movie', 'tv', 'multi'];

    // Check for valid type input
    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie", "tv" or "multi.`);
        return;
    }


    const url = `https://api.themoviedb.org/3/${type}/${id}/similar?language=en-US&page=1`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => { return (json?.results) })
        .catch(err => console.error('Error: ' + err));
}



async function getRecommendations(type, id) {
    const validTypes = ['movie', 'tv', 'multi'];

    // Check for valid type input
    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie", "tv" or "multi.`);
        return;
    }


    const url = `https://api.themoviedb.org/3/${type}/${id}/recommendations?language=en-US&page=1`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => { return (json?.results) })
        .catch(err => console.error('Error: ' + err));
}


async function search(type, query) {
    const validTypes = ['movie', 'tv', 'multi'];

    // Check for valid type input
    if (!validTypes.includes(type)) {
        console.error(`Invalid type "${type}". Expected "movie", "tv" or "multi.`);
        return;
    }


    const url = `https://api.themoviedb.org/3/search/${type}?query=${query}&include_adult=false&language=en-US&page=1`;

    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => { return (json) })
        .catch(err => console.error('Error: ' + err));
}


async function getSeason(id, season_number) {



    const url = `https://api.themoviedb.org/3/tv/${id}/season/${season_number}?language=en-US`;


    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => {
            return (json)
        })
        .catch(err => console.error('Error: ' + err));
}



async function findById(imdb_id) {



    const url = `https://api.themoviedb.org/3/find/${imdb_id}?external_source=imdb_id&language=en`;


    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => {
            return (json)
        })
        .catch(err => console.error('Error: ' + err));
}




async function externalId(type, id) {



    const url = `https://api.themoviedb.org/3/${type}/${id}/external_ids`;


    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
    };

    // Fetch data from the API
    return fetch(url, options)
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(json => {
            return (json)
        })
        .catch(err => console.error('Error: ' + err));
}


export { getDiscovery, getImages, search, getDetails, getSimilar, getRecommendations, getTrending, getGenres, getNowPlaying, getSeason, findById, externalId }