import { Link } from "react-router-dom";


export default function Genres({ genres = [], location = { pathname: '/' } }) {

    console.log(location);

    return (
        <div style={{ position: 'absolute', zIndex: 1000, height: '100vh', width: '100vw', backgroundColor: '#0008', top: 0, left: 0, backdropFilter: 'blur(20px)', WebkitBackdropFilter: 'blur(20px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center', justifyContent: 'center', padding: '50px' }}>
                <Link
                    to={location.pathname}
                    style={{ color: '#fff', textDecoration: 'none', border: '1px solid #fff5', padding: '5px 20px', borderRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <ion-icon name="close"></ion-icon>
                </Link>
                {genres && genres.map((genre, index) => (
                    <Link
                        to={`movies/${genre.id}`}
                        key={genre.name + index}
                        style={{ color: '#fff', textDecoration: 'none', border: '1px solid #fff5', padding: '5px 20px', borderRadius: '20px' }}
                    >
                        {genre.name}
                    </Link>
                ))}
            </div>
        </div>
    )
}