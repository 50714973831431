import { useEffect, useRef, useState, useCallback } from "react";
import MobileHeader from "./components/MobileHeader";
import { getDetails } from "./Themoviedb";
import { getExistingList } from "./firebaseConfig";
import debounce from "lodash.debounce"; // Debounce to optimize search performance
import loadingImg from './image/loading.gif'
import { Link, useLocation } from "react-router-dom";

// Debounce function outside of the component to avoid recreation on every render
const debouncedGetContents = debounce(async (searchTerm, availableContents, setContents, setLoading) => {


    setLoading(true);
    try {
        const contents = await Promise.all(
            availableContents.map(async id => {
                const data = await getDetails('movie', id);
                if (data?.title?.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return data;
                }
                return null;
            })
        );
        setContents(contents.filter(Boolean));
    } catch (error) {
        console.error("Error fetching movie details", error);
    } finally {
        setLoading(false);
    }
}, 300);

function Search() {
    const location = useLocation()

    const [availableContents, setAvailableContents] = useState([]);
    const [contents, setContents] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const bodyRef = useRef(null);

    // Fetch available content IDs once on component mount
    useEffect(() => {
        const fetchAvailableContents = async () => {
            try {
                const data = await getExistingList();
                const list = data.prefixes.map(d => d.name);
                setAvailableContents(list);
            } catch (error) {
                console.error("Error fetching existing list", error);
            }
        };
        fetchAvailableContents();
    }, []);

    // Fetch content details based on search term
    useEffect(() => {
        if (availableContents.length) {
            debouncedGetContents(search, availableContents, setContents, setLoading);
        }

        // Cleanup debounce on unmount
        return () => debouncedGetContents.cancel();
    }, [availableContents, search]);

    return (
        <>
            <MobileHeader
                bodyRef={bodyRef}
                title="Search"
                component={<SearchComponent search={search} setSearch={setSearch} />}
                menu={['All', 'Movies', 'TV Shows']}
            />
            <div className="container" style={{ height: '100vh', margin: '0px', overflow: 'scroll', maxWidth: '100vw', padding: '0px 20px' }} ref={bodyRef}>
                {loading ? (
                    <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={loadingImg} alt="Loadin..." style={{ height: '25px' }} />
                    </div>
                ) : (
                    <div className="movies-grid" style={{ paddingTop: '150px', paddingBottom: '150px', overflow: 'visible' }}>
                        {contents.map((content) => (
                            <Link
                                key={`${content.content_type}-${content.id}`}
                                to={`/${content.media_type}/${content.id}`}
                                state={{ backgroundLocation: location }}
                                className={`movie-item`}>
                                <img
                                    src={`https://image.tmdb.org/t/p/w500/${content.poster_path}`} // Use lower resolution for faster load
                                    alt={content.title}
                                />
                                <div className="overlay"></div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}



function SearchComponent({ search, setSearch }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0px 10px 0px', padding: '10px', gap: '10px', borderRadius: '20px', backgroundColor: '#5555' }}>
                <ion-icon name="search"></ion-icon>
                <input
                    key={'search'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="searchInput"
                    placeholder="Search Plinx"
                />
                {search.trim().length > 0 && (
                    <ion-icon
                        name="close-circle"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSearch('')}
                    ></ion-icon>
                )}
            </div>
        </div>
    )
}

export default Search;
