import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import { getDetails, getRecommendations } from '../Themoviedb';
import ShareModal from './Share';
import { getExistingList, getTrailerUrl } from '../firebaseConfig';
import vdTest from '../image/vidAd.mp4'

export const formatRuntime = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = Math.floor(time % 60);

    return `${hours > 0 ? `${hours}h ` : ''}${hours > 0 ? (minutes < 10 ? '0' : '') : ''}${minutes}m`;
};



function PreviewModal() {
    const navigate = useNavigate()
    const location = useLocation()

    const [availableContents, setAvailableContents] = useState([])

    const [content, setContent] = useState(null)
    const [url, setUrl] = useState(null)
    const [alikeContents, setAlikeContents] = useState([])

    const [sharing, setSharing] = useState(false)

    const { id } = useParams();

    useEffect(() => {
        const avcts = async () => {
            const data = await getExistingList()
            const list = data.prefixes.map(d => d.name)
            setAvailableContents(list)
        }

        avcts()
    }, [])

    useEffect(() => {

        async function fetchContent() {
            const content = await getDetails('movie', id);
            setContent(content)

            const url = await getTrailerUrl(id)

            setUrl(url)

            const alikeContents = await getRecommendations('movie', id);

            const results = await Promise.all(
                alikeContents.map(async (result) => {
                    return availableContents.includes(String(result.id)) ? result : null
                })
            );

            // Filter out the null values (movies that don't exist in storage)
            const filteredResults = results.filter(result => result !== null);
            setAlikeContents(filteredResults)
        }

        fetchContent()
    }, [id, availableContents])

    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(width < 767);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        setIsMobile(width < 767);
    }, [width]);


    const modalref = useRef(null)
    const [y, setY] = useState(0)
    const [x, setX] = useState(0)
    const [scale, setScale] = useState(1)
    const [blur, setBlur] = useState(0)

    function handleYing(e) {
        modalref.current.style.transition = "0s"
        const [startY, startX] = [e.clientY, e.clientX];
        let [deltaY, deltaX, deltaScale, deltaBlur] = [0, 0, 1, 0]

        function moving(e) {
            const [currentY, currentX] = [e.clientY, e.clientX];
            deltaY = currentY - startY;
            deltaX = currentX - startX;
            deltaScale = 1 - Math.abs(deltaY) / window.innerHeight;
            deltaScale = Math.max(deltaScale, 0.9); // Ensure it doesn’t shrink too much

            deltaBlur = Math.min(deltaY / 50, 20)

            setY(deltaY * deltaScale);  // Update the state with the new y value
            setX(deltaX);
            setScale(deltaScale)
            setBlur(deltaBlur)
        }

        function release() {
            const delay = 500
            modalref.current.style.transition = delay + "ms ease"
            if (deltaY > (window.innerHeight / 2) - startY) {
                setY(window.innerHeight)
                setX(0)
                setScale(0)
                setBlur(20)
                setTimeout(() => {
                    // console.log(location.state);
                    navigate(location.state.backgroundLocation.pathname || "/")
                }, delay);
            } else {
                setY(0)
                setX(0)
                setScale(1)
                setBlur(0)
            }

            document.removeEventListener('pointermove', moving);
            document.removeEventListener('pointerup', release);
        }

        document.addEventListener('pointermove', moving);
        document.addEventListener('pointerup', release);
    }


    const [showingTrailer, setShowingTrailer] = useState(0)
    const [muted, setMuted] = useState(false)
    const trailerRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            trailerRef?.current?.play()
        }, 3000);

        console.log(content);
    }, [content, trailerRef])



    return (
        <>
            <div className="modal-overlay">
                <div className="modal-container" ref={modalref} onClick={e => e.stopPropagation()} style={{ transform: `translate(${x}px, ${y}px) scale(${scale})`, filter: `blur(${blur}px)` }}>
                    {/* <div className='header' style={{ borderBottom: '1px solid #ffffff10', marginBottom: '20px', display: isMobile && "none" }}>
                        <h2 style={{ margin: '10px', padding: '0px', textAlign: 'center', fontSize: '18px' }}>{content ? content.title : "Loading..."}</h2>
                    </div> */}
                    <span className="close-button" onClick={() => navigate(location?.state?.backgroundLocation?.pathname || "/")}><ion-icon name="close-outline" style={{ color: "#fff" }}></ion-icon></span>

                    <div className="content-thumbnail" style={{
                        backgroundImage: `url(https://image.tmdb.org/t/p/original/${content?.backdrop_path})`,
                        width: "100%",
                        height: 'unset',
                        minHeight: 'unset',
                        aspectRatio: 16 / 9,
                        flexShrink: 0,
                        overflow: 'hidden',
                        display: 'flex'
                    }}

                        onPointerDown={handleYing}
                        onClick={() => {

                            if (!trailerRef || !url) return
                            trailerRef.current.muted = !trailerRef.current.muted;
                            setMuted(trailerRef.current.muted)
                        }}

                    >
                        <video onEnded={() => setShowingTrailer(0)} onPlay={() => setShowingTrailer(1)} ref={url} src={vdTest} alt={"Trailer"} style={{ height: '100%', width: '100%', backgroundColor: 'black', transition: 'opacity 0.3s', opacity: showingTrailer }} />
                        <span style={{ position: 'absolute', width: 'calc(100% - 20px)', aspectRatio: 16 / 9, textAlign: 'right', alignContent: 'end', opacity: showingTrailer }}>
                            {muted
                                ? <ion-icon name="volume-mute-outline" style={{ color: 'white' }}></ion-icon>
                                : <ion-icon name="volume-high-outline" style={{ color: 'white' }}></ion-icon>
                            }
                        </span>
                    </div>

                    <div className="modal-content movie-preview" style={{ gap: '20px' }}>
                        {/* {content?.images?.logos[0] ? (
                            <div style={{ height: '50px', display: 'flex' }}>
                                <img src={`https://image.tmdb.org/t/p/original/${content?.images.logos[0].file_path}`} alt="MovieName" className="logo" style={{ height: '100%', alignContent: 'left' }} />
                            </div>
                        ) : ( */}
                        <div style={{ marginTop: '10px' }}>
                            <h1 style={{ margin: '0px', textAlign: 'left' }}>{content?.title}</h1>
                        </div>
                        {/* )} */}

                        <p style={{ margin: 0, fontSize: '11px', textAlign: 'left' }}>{new Date(content?.release_date).getFullYear()} ‧ {content?.genres[0].name} ‧ {formatRuntime(content?.runtime)}
                        </p>

                        <div className="row" style={{ margin: "0px" }}>
                            <button className="button" onClick={() => navigate(`/watch/${id}`)}>
                                <ion-icon name="play"></ion-icon>
                                Play
                            </button>
                            <div className="square expandable" style={{ padding: '0px', display: 'flex', backgroundColor: '#fff1', backdropFilter: 'blur(10px)', borderRadius: '20px' }} >
                                <button className="nbbutton square addToButton">
                                    <ion-icon name="add"></ion-icon>
                                </button>

                                <button className="nbbutton square likeButton">
                                    <ion-icon name="heart-outline"></ion-icon>
                                </button>

                                <button className="nbbutton square shareButton" onClick={() => {
                                    setSharing(true)
                                }}>
                                    <ion-icon name="arrow-redo-outline"></ion-icon>
                                </button>
                            </div>
                        </div>

                        {content?.overview && (
                            <div style={{ textAlign: 'left', backgroundColor: "#00000050", padding: "20px", borderRadius: "20px", margin: '0px' }}>
                                <div className='header' style={{ borderBottom: '1px solid #ffffff10', paddingBottom: '5px', marginBottom: '10px' }}>
                                    <b style={{ padding: '0px' }}>Overview</b>
                                </div>
                                {content ? content.overview : "Loading..."}
                            </div>
                        )}


                        <div style={{ textAlign: 'left', backgroundColor: "#00000050", padding: "20px", borderRadius: "20px", margin: '0px' }}>
                            <ContentMiniRow id={`alike-${content?.id}`} array={alikeContents} title={`More like ${content?.title}`} />
                            {/* {content?.content_type === "Song" ? (
                                <ContentMiniRow id={`related-${content?.publicId}`} array={alikeContents.filter(c => c.content_type === "Song" && c.artist === content.artist)} title={`More songs by ${content?.artist}`} />
                            ) : (
                                <ContentMiniRow id={`related-${content?.publicId}`} array={alikeContents} title={`Related to ${content?.title}`} />

                            )} */}

                        </div>
                    </div>
                </div>
            </div>

            {sharing && <ShareModal content={content} onClose={() => setSharing(false)} />}
        </>
    );
}


export function ContentMiniRow({ id, title, array }) {


    function scrollNext(rowIndex) {
        const rowElement = document.querySelector(`#${rowIndex}`);
        if (rowElement) {
            const firstChild = rowElement.firstChild;
            if (firstChild) {
                rowElement.scrollBy({ left: (firstChild.clientWidth + 14) * 2, behavior: 'smooth' });
            }
        }
    }

    function scrollPrev(rowIndex) {
        const rowElement = document.querySelector(`#${rowIndex}`);
        if (rowElement) {
            const firstChild = rowElement.firstChild;
            if (firstChild) {
                rowElement.scrollBy({ left: (firstChild.clientWidth + 14) * -2, behavior: 'smooth' });
            }
        }
    }

    const location = useLocation()


    return (
        <>
            <div className='header' style={{ borderBottom: '1px solid #ffffff10', paddingBottom: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <b style={{ padding: '0px', marginRight: 'auto' }}>{title}</b>

                <button className="button square" onClick={() => scrollPrev(id)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </button>
                <button className="button square" onClick={() => scrollNext(id)}>
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
            </div>
            <div className="row" id={id} style={{ overflowX: "scroll", paddingBottom: "20px", display: "flex" }}>
                {array.map((content) => (
                    <Link
                        to={`/${content.media_type}/${content.id}`}
                        state={location.state}
                        className="s_card card"
                        style={{
                            minWidth: '150px',
                            minHeight: '225px',
                            backgroundImage: `url(https://image.tmdb.org/t/p/original/${content.poster_path})`,
                            height: content.content_type === "Song" && "250px",
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-start'
                        }}
                        key={content.id}
                    >
                    </Link>
                ))}
            </div>
        </>
    )
}

export default PreviewModal;
